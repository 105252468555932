<template>
  <div id="PatientR" class="w-full">

    <div class="w-full">
      <div class="text-center mt-8">
        <imageLazy :imageStyle="{}" :imageClass="'doctorImage'" :src="baseURL + Patient.ProfileImagePath"
          placeHolderType="patient" />
        <div class="">
          <p>{{ Patient.Name }}</p>
        </div>
      </div>
    </div>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table ref="table" :data="Model">
        <template slot="thead">
          <vs-th>{{ $t("Reservation") }}</vs-th>
          <vs-th> {{ $t("Action") }}</vs-th>
          <vs-th> {{ $t("NextOnlineAppointment") }}</vs-th>

        </template>

        <template slot-scope="{ data }">
          <tbody :data="item" :key="indextr" v-for="(item, indextr) in data">
            <vs-tr class="cursor-pointer hover:text-primary">
              <vs-td>
                <p>
                  {{
                    item.DoctorSession.DateFormatted +
                    " " +
                    item.DoctorSession.DateFrom.slice(11, 16)
                  }}
                </p>
              </vs-td>
              <vs-td>
                <div>
                  <a style="text-decoration: underline;" class="linkClass" @click="
                    showDownloadReports(
                      item.PatientID,
                      item.DoctorID,
                      item.ID
                    )
                  ">
                    {{ $t("MedicalReports") }}
                  </a>
                </div>
                <div>
                  <a style="text-decoration: underline;" @click="
                    showPatientDiagnosisPrescription(item.ID, item.PatientID)
                  " class="linkClass">
                    {{ $t("PatientDiagnosisPrescription") }}
                  </a>
                </div>
              </vs-td>
              <vs-td>
                <div style="justify-content: center" v-show="currentDate(item.DoctorSession.DateFrom)">

                  <div>
                    <p style="color: #2753D9">
                      {{ item.DoctorSession.DateFormatted }}
                      at {{ item.DoctorSession.TimeFrom }}
                    </p>
                  </div>
                </div>
              </vs-td>

            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>



    <vs-popup title="" :active.sync="showDownloadReportsfield" style="">
      <showDownloadReportsCom :patientDoc="patientDoc" v-if="showDownloadReportsfield"
        @closePop="showDownloadReportsfield = false"></showDownloadReportsCom>
    </vs-popup>

    <b-row>
      <b-col>
        <div class="mt-8 ">
          <b-button @click="$router.go(-1)">{{
            $t("Back")
          }}</b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import imageLazy from "@/components/image_lazy.vue";
import { domain } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import showDownloadReportsCom from "@/views/doctorProfile/pendingMedicalReportsDownload.vue";
import {
  BButton, BCard, BCol, BFormInput, BInputGroup, BInputGroupAppend, BLink, BRow
} from 'bootstrap-vue';
export default {
  components: {
    showDownloadReportsCom,
    imageLazy, BButton, BCard, BCol, BFormInput, BInputGroup, BInputGroupAppend, BLink, BRow
  },
  data() {
    return {
      Patient: {},
      EnglishLan: false,
      patientDoc: {},
      showDownloadReportsfield: false,
      baseURL: domain,
      Model: [],
      Search: {
        PatientName: "",
      },
    };
  },
  computed: {
    patientReservation() {
      return this.$store.state.patientList.patientReservation;
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    OpenUploadDocument(data) {
      this.ShowUploadDocument = true;
      this.showPendingMedicalReport = false;
      this.documentModel = data;
    },
    showPatientHistory(PatientID) {
      debugger;
      this.$router.push({
        name: "PatientMedicalHistorySession",
        params: { ID: PatientID },
      });
    },
    showDownloadReports(PatientID, DoctorID, PatientReservationSessionID) {
      debugger;
      this.patientDoc.PatientID = PatientID;
      this.patientDoc.DoctorID = DoctorID;
      this.patientDoc.PatientReservationSessionID = PatientReservationSessionID;
      this.showDownloadReportsfield = true;
    },

    showPatientDiagnosisPrescription(patientReservationSessionID, PatientID) {
      debugger;

      this.$router.push({
        name: "PatientDiagnosisPrescription",
        params: {
          patientReservationSessionID: patientReservationSessionID,
          patientID: PatientID,
        },
      });
    },
    currentDate(date) {
      debugger;
      const current = new Date();

      if (date < current) {
        debugger;
        return true;
        //Do your thing
      } else {
        debugger;
        return false;
      }
    },
    searchData(index) {
      debugger;
      if (this.$route.params.DoctorID) {
        var model = {};
        model.doctorID = this.$route.params.DoctorID;
        model.PatientID = this.$route.params.PatientID;
        //model.NotIncludePaymentStatusIds = [1, 3];
        //model.PaymentStatusID = 5; //paid
        model.PaymentStatusID = 3; //paid
        //model.StatusID = 5;//completed
        if (index == 2) {
          if (this.Search.PatientName != "") {
            model.PatientName = this.Search.PatientName;
          }
        }
        this.$store
          .dispatch("DoctorList/getPatientReservation", model)
          .then((res) => {
            if (res.status == 200) {
              this.Model = res.data.Data;
              if (this.Model == null || this.Model.length == 0) {
                this.$vs.notify({
                  title: this.$t("NoData"),
                  text: this.$t("NoDataToshow"),
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "warning",
                });
              }
            }
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
      var x = this.Model.length;
      debugger;
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
        localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    this.$store
      .dispatch("patientList/GetPatient", this.$route.params.PatientID)
      .then((res) => {
        debugger
        this.Patient = res.data.Data;
      });
    this.searchData(1);
  },
};
</script>
<style >
#PatientR .vs-button {
  display: inline;
  background-color: transparent !important;
  color: gray;
  width: 5rem !important;
}

#PatientR fieldset {
  border-collapse: separate;
  border: solid #00000029 1px;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
  padding: 15px;
}

#PatientR .medcine {
  background: #e8e8e8;
}

#PatientR table td {
  padding: 4px;
}

.doctorImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

#PatientR .vs-input {
  width: 90%;
  color: black !important;
}

#PatientR legend {
  margin-left: 2rem;
}
</style>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>

